import { useHover } from 'ahooks';
import { UploadFile, Image, Spin } from 'antd';
import clsx from 'clsx';
import { AiOutlineEye, AiOutlineDelete } from 'react-icons/ai';
import { useRef, useState } from 'react';

interface IProps {
  fileList: UploadFile[];
  isPreview?: boolean;
  isDelete?: boolean;
  onPreview?: (url: string) => void;
  onRemove?: (file: UploadFile) => void;
  style?: React.CSSProperties;
  maxCount?: number;
  children?: React.ReactNode;
}

export function Preview(props: IProps) {
  const {
    fileList,
    isPreview = true,
    isDelete = true,
    onPreview,
    onRemove,
    style,
    maxCount = 1,
    children,
  } = props;

  const [url, setUrl] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHover(ref);

  const renderFile = (uploadFile: UploadFile, index: number) => {
    const fileBlob = uploadFile?.originFileObj as File;
    const file = new File([fileBlob], uploadFile.name);
    const url = URL.createObjectURL(file);
    const src = uploadFile.url ?? url;
    return (
      <div key={uploadFile.uid} data-index={index} className="relative" ref={ref}>
        <Spin
          spinning={['uploading', 'init'].includes(uploadFile.status || '')}
          style={{
            marginRight: fileList.length - 1 === index && maxCount === 1 ? 0 : 12,
          }}>
          <div
            className=" w-[100px] h-[100px] relative"
            style={{
              width: style?.width || 100,
              height: style?.height || 100,
            }}>
            <Image src={src} preview={false} />
            <div
              className={clsx(
                'absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] text-[#FFFFFF] flex justify-center items-center gap-1',
                isHovering ? 'visible' : 'invisible',
              )}>
              {isPreview ? (
                <AiOutlineEye
                  className="cursor-pointer"
                  onClick={() => (onPreview ? onPreview(src) : setUrl(src))}
                />
              ) : null}
              {isDelete ? (
                <AiOutlineDelete
                  className="cursor-pointer"
                  onClick={() => onRemove?.(uploadFile)}
                />
              ) : null}
            </div>
          </div>
        </Spin>
      </div>
    );
  };

  return (
    <>
      <div
        className="flex justify-center items-center flex-col border border-solid border-[#e5e7eb] rounded overflow-hidden"
        style={style}>
        {Boolean(fileList?.length) && fileList.map(renderFile)}
        {children}
      </div>
      <Image
        style={{ display: 'none' }}
        src={url}
        preview={{
          visible: !!url,
          src: url,
          onVisibleChange: (value) => {
            if (!value) return setUrl(undefined);
          },
        }}
      />
    </>
  );
}
