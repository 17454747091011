import { mapProps, connect } from '@formily/react';
import { Field } from '@formily/core';
import { CityCascader as City } from 'src/components/CityCascader';
import type { ICityCascaderProps } from 'src/components/CityCascader';

export const CityCascader = connect(
  City,
  mapProps((props: ICityCascaderProps, field) => {
    return {
      ...props,
      value: props.value || (field as Field).value,
      onChange: (field as Field).onInput,
      error: !!(field as Field).errors?.[0],
    };
  }),
);
