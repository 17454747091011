import { useBoolean, useMemoizedFn } from 'ahooks';
import { Upload as BaseUpload, message } from 'antd';
import type { UploadProps, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { CropModal } from './cropper';
import { isFunction } from 'lodash-es';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { Preview } from './preview';
import { uploadImage } from 'src/api/clients';

interface IProps extends Omit<UploadProps, 'onChange'> {
  fileSize?: number;
  // 是否支持预览
  isPreview?: boolean;
  // 是否支持删除
  isDelete?: boolean;
  // 是否支持上传中删除
  isUploadingDel?: boolean;
  // 是否支持剪裁
  isCrop?: boolean;
  // 是否自定义上传
  isOriginalFile?: boolean;
  // 初始化时过滤文件列表
  initFilter?: (fileList: UploadFile[]) => UploadFile[];
  // 剪裁回调
  onCrop?: (file: UploadFile) => Promise<boolean>;
  // 上传完成回调
  onChange?: (fileList: UploadFile[]) => void;
}

const filterFile = (fileList: UploadFile[]) => {
  return fileList.filter((item) => {
    return item.status === 'done';
  });
};

export function Upload(props: IProps) {
  const {
    isOriginalFile,
    isCrop,
    isDelete,
    isPreview,
    fileList,
    maxCount,
    fileSize = 10 * 1024 * 1024,
    children,
    multiple,
    onCrop,
    onChange,
    initFilter,
    ...rest
  } = props;

  const [files, setFiles] = useState<UploadFile[]>([]);
  const [visibleCrop, { setTrue: showCrop, setFalse: hideCrop }] = useBoolean(false);

  const [loading, { setTrue: setLoading, setFalse: setUnLoading }] = useBoolean(false);
  const [currentFile, setCurrentFile] = useState<UploadFile | null>(null);
  const handleChange = useMemoizedFn((info: { fileList: UploadFile[] }) => {
    const fileList = info.fileList || [];
    if (isOriginalFile) {
      setFiles(info.fileList);
      onChange?.(info.fileList);
      return;
    }

    const newFile = fileList
      .slice(0, maxCount)
      .filter((item) => {
        const error =
          item.status === 'error' || (item.status === 'done' && !item.response.url && !item.url);
        if (error) {
          message.error('上传失败');
        }
        return item.status !== 'error';
      })
      .map((item) => ({ ...item, url: item.url || item.response?.url }));
    setFiles(newFile);
    if (isFunction(onChange) && newFile.every((item) => item.status === 'done')) {
      onChange(newFile);
    }
  });

  const onCropHandler = useMemoizedFn(async (file: UploadFile) => {
    if (onCrop) {
      return await onCrop(file);
    }
    setCurrentFile(file);
    showCrop();
  });

  const onBeforeUpload = (file: UploadFile) => {
    if (maxCount && files.length >= maxCount) {
      message.error(`最多上传${maxCount}个文件`);
      return false;
    }
    if (fileSize && (file?.size || 0) > fileSize * 1024) {
      message.error(`文件大小不能超过${fileSize}KB`);
      return false;
    }
    // 支持剪裁 且 非批量上传
    if (isCrop && !multiple) {
      onCropHandler(file);
      return false;
    }
    return true;
  };

  const customRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await uploadImage(formData);
      onSuccess({ url: result });
      const currentFiles = files?.map((item) =>
        item.uid === file.uid
          ? {
              ...item,
              url: result,
              thumbUrl: result,
              status: 'done' as UploadFileStatus,
              response: { url: result },
            }
          : item,
      );

      setFiles(currentFiles);
      handleChange({ fileList: currentFiles });
    } catch (err) {
      onError(err);
      setFiles((prevList) =>
        prevList.map((item) => (item.uid === file.uid ? { ...item, status: 'error' } : item)),
      );
    }
  };

  const handleDelete = (file: UploadFile) => {
    setFiles((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  // 剪裁上传
  async function handleCropUpload(f: File | null, file: UploadFile) {
    if (!f) {
      handleDelete(file);
      return;
    }

    setLoading();
    try {
      const formData = new FormData();
      formData.append('file', f as File);
      const result = await uploadImage(formData);
      if (!result) return;
      setFiles((pre) => {
        const r = pre.map((item) => {
          if (item.uid === file?.uid) {
            return {
              ...item,
              url: result,
              thumbUrl: result,
              response: { url: result },
              status: 'done' as UploadFileStatus,
            };
          }
          return item;
        });
        return r;
      });
      message.success('剪裁成功');
      hideCrop();
    } catch {
      message.error('剪裁失败');
      return;
    } finally {
      setUnLoading();
    }
  }
  useEffect(() => {
    setFiles(initFilter ? initFilter(fileList || []) : filterFile(fileList || []));
  }, [fileList, initFilter]);

  return (
    <>
      <BaseUpload
        fileList={files}
        {...rest}
        defaultFileList={fileList}
        onChange={handleChange}
        beforeUpload={onBeforeUpload}
        customRequest={customRequest}
        itemRender={() => (
          <Preview
            isDelete={isDelete}
            isPreview={isPreview}
            fileList={files}
            onRemove={handleDelete}
          />
        )}>
        {(files ?? []).length < (maxCount || 1) ? children : null}
      </BaseUpload>
      <CropModal
        confirmLoading={loading}
        open={visibleCrop}
        onCancel={hideCrop}
        file={currentFile}
        onChange={handleCropUpload}
      />
    </>
  );
}
