import type { IFormSchema } from 'src/components/Form';

export const schema: IFormSchema = {
  nickName: {
    type: 'string',
    title: '昵称',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入昵称',
    },
    'x-validator': [{ required: true, message: '请输入昵称' }],
  },
  sex: {
    type: 'string',
    title: '性别',
    'x-decorator': 'FormItem',
    'x-component': 'Select',
    'x-component-props': {
      placeholder: '请选择性别',
      options: [
        { label: '男', value: '0' },
        { label: '女', value: '1' },
        { label: '未知', value: '2' },
      ],
      allowClear: true,
    },
    'x-validator': [{ required: true, message: '请选择性别' }],
  },
  birthday: {
    type: 'string',
    title: '生日',
    'x-decorator': 'FormItem',
    'x-component': 'DatePicker',
    'x-component-props': {
      placeholder: '请选择生日',
      allowClear: false,
    },
    'x-validator': [{ required: true, message: '请选择生日' }],
  },
  administrative: {
    type: 'string',
    title: '所属区域',
    'x-decorator': 'FormItem',
    'x-component': 'CityCascader',
    'x-component-props': {
      placeholder: '请选择所属区域',
    },
    'x-validator': [{ required: true, message: '请选择所属区域' }],
  },
  address: {
    type: 'string',
    title: '具体地址',
    'x-decorator': 'FormItem',
    'x-component': 'Input.TextArea',
    'x-component-props': {
      placeholder: '请输入具体地址',
      rows: 4,
    },
    'x-validator': [{ required: true, message: '请输入具体地址' }],
  },
  remark: {
    type: 'string',
    title: '个人简介',
    'x-decorator': 'FormItem',
    'x-component': 'Input.TextArea',
    'x-component-props': {
      placeholder: '请输入个人简介',
      rows: 4,
    },
  },
};
