import { useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import { Input, Select, DatePicker } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { CityCascader } from 'src/components/formily/CityCascader';
import { updatePersonal } from 'src/api/clients';
import { schema } from './schema';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { UpdatePersonal } from 'src/api/types/system';

export function BaseInfo() {
  const { userInfo } = useUserInfo();

  const formRef = useRef<FormRef>(null);

  const handleSubmit = async () => {
    const instance = formRef.current?.getFormInstance();
    const value = await instance?.submit<
      Omit<UpdatePersonal, 'administrative'> & { administrative: string[] }
    >();
    if (!value) return;
    const { administrative, ...rest } = value;
    await updatePersonal({
      ...rest,
      administrative: administrative.join(','),
    });
    message.success('更新成功');
  };

  useEffect(() => {
    const instance = formRef?.current?.getFormInstance();
    if (!instance || !userInfo) {
      return;
    }
    const { nickName, sex, birthday, administrative, address, remark } = userInfo;
    instance?.setValues({
      nickName,
      sex,
      birthday,
      administrative: administrative ? administrative.split(',') : undefined,
      address,
      remark,
    });
  }, [userInfo]);

  return (
    <div className="max-w-[500px]">
      <Form
        ref={formRef}
        className="py-6"
        schema={schema}
        layout={{ labelWidth: 125 }}
        components={{ Input, Select, DatePicker, CityCascader }}
        hideAction
      />
      <div className="pl-[125px]">
        <Button type="primary" onClick={handleSubmit}>
          提交
        </Button>
      </div>
    </div>
  );
}
