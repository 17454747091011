import { useRequest } from 'ahooks';
import { Cascader } from 'antd';
import type { CascaderProps } from 'antd';
import { getDistrictList } from 'src/api/clients/tools';
import { IDistrictItem } from 'src/api/types/tools';

export interface ICityCascaderProps extends CascaderProps {
  className?: string;
}

export function CityCascader(props: ICityCascaderProps) {
  const { ...rest } = props;
  const transformDistrictsToOptions = (districts: IDistrictItem[]): CascaderProps['options'] => {
    return districts.map((item) => ({
      value: item.adcode,
      label: item.name,
      isLeaf: !item.districts?.length, // 如果没有子级，标记为叶子节点
      children: item.districts ? transformDistrictsToOptions(item.districts) : undefined,
    }));
  };
  const { data: option, loading } = useRequest(async () => {
    const res = await getDistrictList({ subdistrict: 3 });
    return transformDistrictsToOptions(res?.[0]?.districts || []);
  });

  return <Cascader options={option} loading={loading} {...(rest as any)} />;
}
