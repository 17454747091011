import { Modal, Button, Image, message } from 'antd';
import type { ModalProps, UploadFile } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useEffect, useRef, useState } from 'react';
import { useBoolean } from 'ahooks';

interface IProps extends ModalProps {
  onChange: (file: File | null, uploadFile: UploadFile) => Promise<void>;
  file: UploadFile | null;
}

export function CropModal(props: IProps) {
  const { open, onCancel, onChange, file, confirmLoading, ...extra } = props;
  const cropperRef = useRef<Cropper>();
  const [loading, { setTrue: setLoading, setFalse: setUnLoading }] = useBoolean(false);
  const [visible, setVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string>('');

  const handlePreview = () => {
    const cropper = cropperRef.current;
    if (!cropper) {
      return;
    }
    const canvas = cropper.getCroppedCanvas();
    const url = canvas.toDataURL();
    setImagePreviewSrc(url);
    setVisible(true);
  };

  const handleCropUpload = () => {
    if (!file) return message.error('请上传图片');
    setLoading();
    try {
      cropperRef.current?.getCroppedCanvas().toBlob((blob) => {
        if (!blob) {
          message.error('剪裁失败');
          return;
        }
        const newFile = new File([blob], file?.name || 'image', {
          type: file?.type || 'image/*',
        });
        onChange(newFile, file);
        setVisible(false);
      });
    } catch (error) {
      console.log('剪裁错误 : ', error);
      message.error('剪裁失败');
    } finally {
      setUnLoading();
    }
  };

  const handleCancel = (e: any) => {
    if (!file) {
      message.error('请上传图片');
      onCancel?.(e);
      return;
    }
    onChange?.(null, file);
    onCancel?.(e);
  };

  useEffect(() => {
    if (!file) return;
    const blob = new Blob([file as any]);
    const url = URL.createObjectURL(blob);
    setImageSrc(url);
  }, [file]);

  return (
    <Modal
      title="裁剪图片"
      width={500}
      open={open}
      closeIcon={null}
      footer={
        <div className="flex justify-end gap-2">
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={handlePreview}>预览</Button>
          <Button type="primary" loading={confirmLoading || loading} onClick={handleCropUpload}>
            剪裁
          </Button>
        </div>
      }
      {...extra}>
      <>
        <Cropper
          aspectRatio={undefined}
          viewMode={2}
          dragMode="move"
          src={imageSrc}
          background={false}
          autoCropArea={0.5}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          checkOrientation={false}
          style={{ height: 300, width: '100%' }}
          onInitialized={(instance) => {
            cropperRef.current = instance;
          }}
        />
        <Image
          width={200}
          style={{ display: 'none' }}
          src={imagePreviewSrc}
          preview={{
            visible,
            src: imagePreviewSrc,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </>
    </Modal>
  );
}
